/* eslint-disable prettier/prettier */
import Metamask from "./WalletIcons/metamaskWallet.png";
import Coin98 from "./WalletIcons/Coin98.png";
import WalletConnect from "./WalletIcons/wallet-connect.svg";
import MathWallet from "./WalletIcons/MathWallet.svg";
import TokenPocket from "!file-loader!./WalletIcons/TokenPocket.svg";
import SafePal from "!file-loader!./WalletIcons/SafePal.svg";
import TrustWallet from "./WalletIcons/TrustWallet.png";
import CoinBase from "./WalletIcons/coinbase.png";

export function activateInjectedProvider(providerName) {
  const { ethereum } = window;

  if (!ethereum?.providers) {
    return undefined;
  }

  let provider;

  switch (providerName) {
    case 'Coinbase':
      provider = ethereum.providers.find(({ isCoinbaseWallet }) => isCoinbaseWallet);
      break;
    case 'Metamask':
      provider = ethereum.providers.find(({ isMetaMask }) => isMetaMask);
      break;
    default:
      return;
  }

  if (provider) {
    ethereum.setSelectedProvider(provider);
  }
}
export const connectors = [
  {
    title: "Metamask",
    icon: Metamask,
    connectorId: "injected",
    priority: 1,
  },
  {
    title: "WalletConnect",
    icon: WalletConnect,
    connectorId: "walletconnect",
    priority: 2,
  },
  {
    title: "Trust Wallet",
    icon: TrustWallet,
    connectorId: "injected",
    priority: 3,
  },
  {
    title: "Coinbase",
    icon: CoinBase,
    connectorId: "injected",
    priority: 999,
  },
  {
    title: "MathWallet",
    icon: MathWallet,
    connectorId: "injected",
    priority: 999,
  },
  {
    title: "TokenPocket",
    icon: TokenPocket,
    connectorId: "injected",
    priority: 999,
  },
  {
    title: "SafePal",
    icon: SafePal,
    connectorId: "injected",
    priority: 999,
  },
  {
    title: "Coin98",
    icon: Coin98,
    connectorId: "injected",
    priority: 999,
  },
];

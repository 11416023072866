/* eslint-disable prettier/prettier */

import { Card, Button } from "antd";
import React, { useState, useEffect } from "react";
import { useMoralis } from "react-moralis";
import { Moralis } from "moralis";
import { MerkleTree } from 'merkletreejs';

export default function Mint() {
    const keccak256 = require('keccak256');
    const [mintAmount, setMintAmount] = useState(1);
    const { isAuthenticated, provider, user, account } = useMoralis();
    const [feedback, setFeedback] = useState("");
    const [price, setPrice] = useState("");
    const [displayPrice, setDisplayPrice] = useState("");
    const [maxSupply, setMaxSupply] = useState("");
    const [totalMinted, setTotalMinted] = useState("");
    const [mintBtnDisabled, setMintBtnDisabled] = useState(true);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [totalLeft, setTotalLeft] = useState("");
    const [feedbackColor, setFeedbackColor] = useState("");
    const [theOwner, setTheOwner] = useState();
    const [privateMintActive, setPrivateMintActive] = useState();
    const [isUserOwner, setIsUserOwner] = useState();
    const [isAdminButtonVisible, setIsAdminButtonVisible] = useState();

    const chainID = "4";
    const chainName = "Ethereum Rinkeby";
    const currencyValue = "ETH";
    const collectionName = "TomorrowsWorld";
    const contractid = "0x2D693a375ee8579980Bf1cc9d55f41F404706264";
    const contractabi = require('../contracts/abi.json');
    const addresses = [
        "0x4e68F453805f153575a1e71d29Fe8f161FceD918",
        "0x64801B2982Fd6fb0FAd761fBAA56d82286AE865b",
        "0x8626f6940E2eb28930eFb4CeF49B2d1F2C9C1199",
        "0xb0Ff2C6F5fe8B39A0cEDA2fa337FF12aFC6d1180",
        "0x8c504FC88F5e93746995a84fd9B9e7355559fe47"
    ];

    const btnStyle = {
        width: "auto",
        height: "auto",
        margin: "0px auto",
        borderRadius: "50%",
        fontSize: "16px",
        fontWeight: "500",
        backgroundColor: "#000",
        borderColor: "#fff",
        marginBottom: "20px",
        padding: "25px 22px",
        display: "block"
    };

    function incrementMintCount() {
        let newMintAmount = mintAmount + 1;
        if (newMintAmount > 10) {
            newMintAmount = 10;
        }
        setMintAmount(newMintAmount);
    }

    function decrementMintCount() {
        let newMintAmount = mintAmount - 1;
        if (newMintAmount < 1) {
            newMintAmount = 1;
        }
        setMintAmount(newMintAmount);
    }

    async function doMint() {
        let totalCostWei = "";
        if (isAuthenticated && provider) {
            const leaves = addresses.map(x => keccak256(x));
            const tree = new MerkleTree(leaves, keccak256);
            const leaf = keccak256(user.attributes.ethAddress);
            const proof = [tree.getHexProof(leaf).join('","')];
            if (provider.networkVersion == chainID) {
                if (isUserOwner) {
                    totalCostWei = "0";
                } else {
                    totalCostWei = (price * mintAmount) + "";
                }
                if (privateMintActive) {
                    await Moralis.executeFunction({
                        functionName: "privateMint",
                        abi: contractabi,
                        contractAddress: contractid,
                        params: {
                            proof: proof,
                            quantity: mintAmount,
                        },
                        msgValue: String(totalCostWei)
                    });
                } else {
                    await Moralis.executeFunction({
                        functionName: "mint",
                        abi: contractabi,
                        contractAddress: contractid,
                        params: {
                            quantity: mintAmount,
                        },
                        msgValue: String(totalCostWei)
                    });
                }
            } else {
                setFeedback("Switch to " + chainName);
            }
        }
    }

    async function getData() {
        if (isAuthenticated && provider) {
            if (provider.networkVersion == chainID) {
                await Moralis.executeFunction({
                    functionName: "owner", abi: contractabi, contractAddress: contractid
                }).then(response => {
                    setTheOwner(response);
                });
                await Moralis.executeFunction({
                    functionName: "mintCount", abi: contractabi, contractAddress: contractid
                }).then(response => {
                    setTotalMinted(response.toString());
                });
                await Moralis.executeFunction({
                    functionName: "maxSupply", abi: contractabi, contractAddress: contractid
                }).then(response => {
                    setMaxSupply(response.toString());
                    if (!isNaN(maxSupply) && !isNaN(totalMinted)) {
                        setTotalLeft(parseInt(maxSupply) - parseInt(totalMinted));
                    }
                });
                await Moralis.executeFunction({
                    functionName: "privateMintStarted", abi: contractabi, contractAddress: contractid
                }).then(response => {
                    setPrivateMintActive(response);
                });
                if (privateMintActive) {
                    await Moralis.executeFunction({
                        functionName: "presaleCost", abi: contractabi, contractAddress: contractid
                    }).then(response => {
                        setPrice(response.toString());
                        setDisplayPrice(Moralis.Units.FromWei(response.toString()) + currencyValue);
                    });
                } else {
                    await Moralis.executeFunction({
                        functionName: "cost", abi: contractabi, contractAddress: contractid
                    }).then(response => {
                        setPrice(response.toString());
                        setDisplayPrice(Moralis.Units.FromWei(response.toString()) + currencyValue);
                    });
                }
                if (price != 0) {
                    if (totalLeft != 0) {
                        if (!isDataLoaded) {
                            setIsDataLoaded(true);
                            if (privateMintActive) {
                                setMintBtnDisabled(false);
                                setFeedback(collectionName + " Presale is Open");
                                setFeedbackColor("#16CB4A");
                                window.scrollTo(0, 0);
                            } else {
                                setMintBtnDisabled(false);
                                setFeedback(collectionName + " Sale is Open");
                                setFeedbackColor("#16CB4A");
                                window.scrollTo(0, 0);
                            }
                        }
                    } else {
                        if (totalLeft == 0) {
                            setFeedback(collectionName + " Sold Out");
                            setMintBtnDisabled(true);
                            setFeedbackColor("#ee6c6c");
                            window.scrollTo(0, 0);
                        }
                    }
                }
            } else {
                setMintBtnDisabled(true);
            }
        }
    }

    useEffect(() => {
        if (!isDataLoaded) {
            getData();
        }
        if (account && theOwner) {
            if (account.toLowerCase() == theOwner.toLowerCase()) {
                setIsUserOwner(true);
                setIsAdminButtonVisible(true);
            } else {
                setIsUserOwner(false);
                setIsAdminButtonVisible(false);
            }
        }
    });

    return (
        <div
            style={{
                margin: "auto",
                display: "flex",
                gap: "20px",
                marginTop: "25",
                width: "100%",
                flexWrap: "wrap",
                marginBottom: "40px"
            }}
        >
            {isAdminButtonVisible ? (
                <a
                    style={{
                        margin: "50px auto 0",
                        display: "block",
                        width: "fit-content",
                        textAlign: "center",
                        background: "#8f3030",
                        color: "white",
                        padding: "5px 15px"
                    }}
                    href="/admin">👨‍💻 Admin Panel</a>
            ) : (
                <p style={{ display: "none" }}></p>
            )
            }
            <div
                style={{
                    margin: "auto",
                    display: "flex",
                    gap: "20px",
                    marginTop: "25",
                    width: "100%",
                    maxWidth: "1220px",
                    flexWrap: "wrap",
                    flexFlow: "row wrap",
                    alignItems: "center",
                    justifyContent: "center"
                }}
                className="mintcols"
            >
                <div style={{
                    flex: "1 0 55%",
                    width: "55%",
                }}
                    className="mintCard">
                    <div className="hideMobile" style={{ flex: "1 0 100%", width: "100%", marginTop: "50px" }}>
                        <p style={{
                            textAlign: "center", marginBottom: "20px", color: "#93aebb",
                            fontFamily: "'PT Sans', sans-serif", fontSize: "16px", padding: "0 9%"
                        }}>
                            Mint Status: <span style={{ textTransform: "capitalize", color: feedbackColor }}>{feedback}</span>
                        </p>
                    </div>
                    <Card
                        size="large"
                        style={{
                            background: "#131313",
                            border: "2px solid #8f7430",
                            width: "90%",
                            marginLeft: "2rem"
                        }}
                    >
                        <div style={{
                            flex: "0 1 100%",
                            width: "100%"
                        }}
                            className="leftCol">
                            <p style={{
                                flex: "1 0 100%",
                                width: "100%",
                                textAlign: "center",
                                marginTop: "20px",
                                padding: "10px",
                                color: "red",
                                display: "none",
                                overflow: "hidden"
                            }}>{feedback}</p>
                            <p style={{
                                flex: "1 0 100%",
                                textAlign: "center",
                                fontSize: "34px",
                                lineHeight: "32px",
                                padding: "0 0 10px",
                                margin: "10px auto 0",
                                color: "#fff",
                                fontWeight: "600",
                                fontFamily: "'Open Sans', sans-serif"
                            }}>{collectionName}</p>
                            <p style={{
                                fontSize: "12px", color: "white", textAlign: "center", margin: "14px auto", background: "#191919", width: "fit-content", padding: "10px 20px", borderRadius: "15px"
                            }}>
                                <span style={{ display: "block", width: "100%", fontWeight: "200" }}>Mint Price</span>
                                <span style={{ display: "block", width: "100%", fontWeight: "bold" }}>{displayPrice}</span>
                            </p>
                            <p style={{
                                fontSize: "12px", color: "white", textAlign: "center", margin: "14px auto", background: "#191919", width: "fit-content", padding: "10px 20px", borderRadius: "15px"
                            }}>
                                <span style={{ display: "block", width: "100%", fontWeight: "200" }}>Total Supply</span>
                                <span style={{ display: "block", width: "100%", fontWeight: "bold" }}>{maxSupply}</span>
                            </p>
                            <p style={{
                                fontSize: "12px", color: "white", textAlign: "center", margin: "14px auto", background: "#191919", width: "fit-content", padding: "10px 20px", borderRadius: "15px"
                            }}>
                                <span style={{ display: "block", width: "100%", fontWeight: "200" }}>Total Left</span>
                                <span style={{ display: "block", width: "100%", fontWeight: "bold" }}>{totalLeft}</span>
                            </p>
                            <hr className="showMobile" noshade="true" style={{ width: "50%", margin: "20px auto" }} />
                            <p className="showMobile" style={{ color: feedbackColor, textAlign: "center", marginBottom: "20px" }}>{feedback}</p>
                            <div
                                className="counterBtns"
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    paddingBottom: "20px",
                                    width: "37%",
                                    margin: "10px auto 0px"
                                }}>
                                <Button size="large" type="primary" className="mainBtn"
                                    style={{
                                        borderRadius: "50%",
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        backgroundColor: "#000",
                                        color: "#fff",
                                        border: "1px solid white"
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        decrementMintCount();
                                    }}
                                >
                                    -
                                </Button>
                                <p style={{
                                    flex: "auto",
                                    margin: "5px 10px 0",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    textAlign: "center",
                                    color: "white",
                                    fontFamily: "'PT Sans', sans-serif",
                                    background: "#2c2c2c",
                                    borderRadius: "5px",
                                    padding: "2px 0px",
                                    height: "30px",
                                    border: "1px solid white"
                                }}>{mintAmount}</p>
                                <Button size="large" type="primary" className="mainBtn"
                                    style={{
                                        borderRadius: "50%",
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        backgroundColor: "#000",
                                        color: "#fff",
                                        border: "1px solid white"
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        incrementMintCount();
                                    }}
                                >
                                    +
                                </Button>
                            </div>
                            <Button size="large" type="primary" className="mainBtn"
                                style={btnStyle}
                                disabled={mintBtnDisabled}
                                onClick={async () => {
                                    doMint().then(() => {
                                        setFeedback("Congrats ! Your purchase was processed, enjoy your nfts !");
                                        setFeedbackColor("#16CB4A");
                                        window.scrollTo(0, 0);
                                    }).catch(e => {
                                        setFeedback(JSON.stringify(e).split('[')[1].split('","')[0].replace(']', ':'));
                                        setFeedbackColor("#ee6c6c");
                                        window.scrollTo(0, 0);
                                    })
                                }}
                            >Mint</Button>
                        </div>
                    </Card>
                </div>
                <div style={{ flex: "1 0 43%", width: "43%", position: "relative" }} className="character" >
                    <img src="img/goldworld.gif" style={{ zIndex: "5", position: "relative", borderRadius: "50%" }} />
                </div>
            </div>
        </div >
    );
}